import createAPIHandler from "../base/apiFactory";

const API_URL = 'https://api.clinichunter.com';

export const clinicHunterClinicAPI = createAPIHandler({
  // url: process.env.REACT_APP_CLINIC_HUNTER_API_URL as string,
  url: API_URL,
  defaultHeaders: {
    "Accept": "application/ld+json",
    "Content-type": "application/json"
  },
  mapper: (data: any): any => {
    return data;
  },
  isList: false
});
